import React from "react"
import AboutDrPageSection from "../../components/AboutDrPageSection"
import Layout from "../../components/layout/Layout"
import Seo from "../../components/layout/Seo"

export default function AboutUsPage() {
  return (
    <Layout path="/about-us">
      <Seo
        title="About Dr. Theodore Chamberlain"
        description="About dr. Theodore chamberlain in clearwater Florida. Studies, certifications, practices, and more."
      />
      <AboutDrPageSection />
    </Layout>
  )
}
